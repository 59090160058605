@import url(~antd/dist/antd.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --red-10: #d94647;
  --red-20: #cd1f30;
  --red-30: #d63d4b;
  --red-40: #ff4758;
  --blue-10: #33469a;
  --blue-20: #00539a;
  --gray-10: #cccccc;
  --gray-20: #505050;
  --gray-30: #e3e3e3;
  --gray-40: #4e4e4e;
  --gray-50: #2e2e2e;
  --gray-60: #b1b1b1;
  --gray-70: #999999;
  --gray-80: #c0bebe;
  --gray-90: #f8f8f8;
  --green-40: #1e4848;
  --green-10: #42ca54;
  --black-10: #101010;
  --brown-10: #c96a5e;
  --yellow-10: #d8c22a;
  --purple-10: #892da3;
  font-family: 'Nunito Sans', sans-serif;
}

body {
  font-family: 'Nunito Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Utilities  */
/* Text Colors */
.text-red-10 {
  color: var(--red-10);
}

.text-green-10 {
  color: var(--green-10);
}

.text-yellow-10 {
  color: var(--yellow-10);
}

.text-purple-10 {
  color: var(--purple-10);
}

.text-brown-10 {
  color: var(--brown-10);
}

.text-green-40 {
  color: var(--green-40);
}

.text-red-20 {
  color: var(--red-20);
}

.text-red-30 {
  color: var(--red-30);
}

.text-red-40 {
  color: var(--red-40);
}

.text-blue-10 {
  color: var(--blue-10);
}

.text-blue-20 {
  color: var(--blue-20);
}

.text-gray-10 {
  color: var(--gray-10);
}

.text-gray-20 {
  color: var(--gray-20);
}

.text-gray-30 {
  color: var(--gray-30);
}

.text-gray-90 {
  color: var(--gray-90);
}

.text-gray-40 {
  color: var(--gray-40);
}

.text-gray-50 {
  color: var(--gray-50);
}

.text-gray-60 {
  color: var(--gray-60);
}

.text-black-10 {
  color: var(--black-10);
}

/* Background Colors */
.bg-yellow-10 {
  background: var(--yellow-10);
}

.bg-brown-10 {
  background: var(--brown-10);
}

.bg-purple-10 {
  background: var(--purple-10);
}

.bg-green-40 {
  background: var(--green-40);
}

.bg-green-10 {
  background: var(--green-10);
}

.bg-gray-80 {
  background: var(--gray-80);
}

.bg-red-10 {
  background: var(--red-10);
}

.bg-red-20 {
  background: var(--red-20);
}

.bg-blue-10 {
  background: var(--blue-10);
}

.bg-blue-20 {
  background: var(--blue-20);
}

.bg-black-10 {
  background: var(--black-10);
}

.bg-gray-10 {
  background: var(--gray-80);
}

.bg-gray-50 {
  background: var(--gray-50);
}

.bg-gray-90 {
  background: var(--gray-90);
}

.bg-brown-10 {
  background: var(--brown-10);
}

/* Borders */
.border-h-gray {
  border-top: 1px solid #707070;
}

.border-gray {
  border-color: #c5c5c5;
}

.border-gray-90 {
  border-color: var(--gray-90);
}

.border-gray-20 {
  border-color: var(--gray-70);
}

.border-gray-30 {
  border-color: var(--gray-80);
}

.opac-bg {
  background-color: rgba(0, 0, 0, 0.3);
}

.card-rounded {
  border-radius: 20px;
}

.rounded-top {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.text-control-1 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-control-2 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-control-3 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f1f3;
  border-radius: 50%;
}

.avatar i {
  font-size: 1.2rem;
}

.avatar img {
  width: 100%;
  height: 100%;
}

.navbar-expo-brand {
  width: 126px;
  height: 126px;
}

.expo_thumbnail {
  overflow: hidden;
  aspect-ratio: 1/1;
  width: 100px;
}

.featured-msme-image {
  aspect-ratio: 3/1;
  border-radius: 18px;
  border: 1px solid #707070;
  background: #000;
  overflow: hidden;
}

.expo_thumbnail img,
.featured-msme-image img {
  width: 100%;
  height: 100%;
}

.ant-modal-content {
  border-radius: 18px;
  overflow: hidden;
}

.ant-modal-header {
  border: none;
}

.facebook,
.twitter,
.gmail,
.instagram,
.contact {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.facebook:hover,
.twitter:hover,
.gmail:hover,
.instagram:hover,
.contact:hover {
  color: #fff;
}

.facebook {
  background: #00539a;
}

/* .facebook::before{
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 320 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>');
  width: 20px;
  height: 20px;
} */

.twitter {
  background: #54abed;
}

.gmail {
  background: #f44336;
}

.instagram {
  background: #d33d95;
}

.contact {
  letter-spacing: 1px;
  width: auto;
  padding: 0 15px;
}

.custom-upload {
  margin: 0;
}

.btn-action{
  display: flex;
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 0;
}

.btn-action span {
  display: block;
  cursor: pointer;
  width: 44px;
  height: 44px;
}

.btn-action span:hover {
  background-color: #d63d4b;
}

.custom-upload .file-upload,
.custom-upload .file-upload i {
  display: block;
  width: 38px;
  height: 38px;
}

.custom-upload .ant-upload-list {
  display: none;
}

.btn-action,
.custom-upload .ant-upload {
  /* display: none; */
  border-radius: 0 0 0 8px;
  overflow: hidden;
}

.hasErrorField {
  border-color: #e42f30 !important;
}

.card-img-placeholder {
  background: url('./shared/assets/images/icon/booth/exhibitor_icon_placeholder.png');
  background-color: #f3efef;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30%;
}

.bg-main-landing {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('./shared/assets/images/banner/home2.jpg');
}

.bg-main-expo {
  /* background: url('./shared/assets/images/banner/expo.png'); */
  background: #5e031e;
}

.bg-main-content {
  background: url('./shared/assets/images/bg/main.png');
  background-position: top center !important;
}

.bg-featured-expo {
  background: url('./shared/assets/images/bg/expo.jpg');
}

.bg-featured-offer {
  background: url('./shared/assets/images/bg/offer.jpg');
}

.bg-main-contact {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('./shared/assets/images/bg/main.png');
  background-position: top center !important;
}

.bg-main-hall {
  background: url('./shared/assets/images/bg/bg-hall.png');
}

.bg-main-exhibitor {
  background: url('./shared/assets/images/bg/bg-exhibitor.jpg');
}

.bg-main-seminar {
  background: url('./shared/assets/images/bg/bg-seminar.png');
  background-size: contain !important;
}

.banner {
  background: url('./shared/assets/images/banner/title-banner.png');
}

.bg-main-header {
  background: url('./shared/assets/images/bg/landing.png');
}

.bg-main-login {
  background: url('./shared/assets/otop/login.png');
}

.bg-new-promo {
  width: 100%;
  height: 500px;
  background: url('./shared/assets/images/banner/exclusive-promo.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
}

.ant-input-number-focused {
  box-shadow: none !important;
}

.upcoming {
  overflow: hidden;
  position: relative;
}

.upcoming h3 {
  position: absolute;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
  /* Starting position */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -moz-animation: upcoming 30s linear infinite;
  -webkit-animation: upcoming 30s linear infinite;
  animation: upcoming 30s linear infinite;
}

/* Move it (define the animation) */
@-moz-keyframes upcoming {
  0% {
    -moz-transform: translateX(100%);
  }

  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes upcoming {
  0% {
    -webkit-transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes upcoming {
  0% {
    -moz-transform: translateX(100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(100%);
    /* Firefox bug fix */
    transform: translateX(100%);
  }

  100% {
    -moz-transform: translateX(-100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-100%);
    /* Firefox bug fix */
    transform: translateX(-100%);
  }
}

.custom-slick-dots {
  display: flex !important;
  margin: 5rem;
}

.custom-slick-dots li {
  position: relative;
  display: flex;
  flex-direction: row;
}

.custom-slick-dots li button {
  color: transparent !important;
  background: #fff;
  width: 1rem;
  height: 1rem;
  border-radius: 50px;
  margin: 0 5px;
  outline: none;
}

.custom-slick-dots li.slick-active button {
  color: #fff;
  width: 50px !important;
}

.link {
  cursor: pointer;
}

* {
  -ms-overflow-style: none;
}

.product {
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.product::-webkit-scrollbar {
  display: none;
}

.btn {
  font-size: 1.1em;
  border-radius: 1rem;
  border: 0;
  letter-spacing: 1px;
  padding: 5px 10px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-left {
  font-size: 1.1em;
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  border: 0;
  letter-spacing: 1px;
  padding: 5px 10px;
  white-space: nowrap;
}

.btn-red {
  background: #cd1f30;
  color: #ffffff;
}

.btn-blue {
  background: #00539a;
  color: #ffffff;
}

.btn-red:hover {
  border-color: none;
  background: #e42f30 !important;
  outline: 0;
}

.ant-upload-list-picture-card-container {
  height: 15rem !important;
  width: 15rem !important;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border: 1px solid #cccccc;
}

.player-wrapper {
  position: relative;
  /* padding-top: 56.25%; */
  aspect-ratio: 21/9;
  background: #000;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

a.btn-red {
  color: #fff !important;
}

a.btn-blue {
  color: #fff !important;
}

.seminar-landing {
  /* min-height: calc(100vh - 80px); */
}

.Description::-webkit-scrollbar {
  width: 0;
}

.rounded-btn {
  border-radius: 50%;
  text-align: center;
  border: 'none';
}

.bg-featured-product {
  background: url('./shared/assets/otop/otop-hub banner.png');
  height: 2rem;
}

.bg-otop-main {
  background: url('./shared/assets/otop/main banner.png');
}

.categories::-webkit-scrollbar {
  display: none;
}

.ck-editor__editable_inline {
  min-height: 400px;
}

.ant-upload-list-item-name {
  color: #101010 !important;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 1;
  left: 0;
  right: 0;
  -webkit-transition: opacity 0.25s ease;
  z-index: 1001;
  margin: 0 auto;
}

.tag {
  display: inline;
  text-align: center;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.tag-started {
  color: #fff;
  background-color: #2857a5;
  border-radius: 100px;
  padding: 2px 10px;
}

.tag-completed {
  color: #fff;
  background-color: #6df0ae;
  border-radius: 100px;
  padding: 2px 10px;
}

.tag-pending {
  color: #ccb830;
  background-color: #f7efbb;
  border-radius: 100px;
  padding: 2px 10px;
}

.tag-declined {
  color: #fff;
  background-color: #d63d4b;
  border-radius: 100px;
  padding: 2px 10px;
}

.tag-to-ship {
  color: #fff;
  background-color: #d8c22a;
  border-radius: 100px;
  padding: 2px 10px;
}

.tag-to-receive {
  color: #fff;
  background-color: #892da3;
  border-radius: 100px;
  padding: 2px 10px;
}

.tag-order-accepted {
  color: #fff;
  background-color: #1e4848;
  border-radius: 100px;
  padding: 2px 10px;
}

@media screen and (max-width: 1050px) {
  .main-container {
    width: 65.8rem;
  }

  .header-container {
    width: 65.8rem;
  }

  .header-container-top {
    width: 65.8rem;
  }
}

@media screen and (min-width: 1051px) {
  .main-container {
    width: 100%;
  }

  .banner-bg {
    width: 100%;
  }

  .header-container {
    width: 100%;
  }

  .header-container-top {
    width: 100%;
  }
}

@media screen and (max-width: 1054px) {
  .content-bg {
    margin-left: auto;
  }
}

@media screen and (max-width: 1064px) {
  .header-container-top {
    margin-left: auto;
  }

  .public-header-content {
    margin-left: auto;
  }
}

.ant-notification-topRight {
  left: 0px !important;
  top: 40% !important;
  right: 40% !important;
}

.ant-notification-notice {
  border-radius: 20px !important;
  background-color: #cd1f30 !important;
  color: #fff !important;
}

.ant-notification-notice .ant-notification-notice-message {
  padding: 3rem 2rem !important;
  color: #fff !important;
}

.exhibitor-logo:hover .relative img {
  height: 20rem !important;
  width: 20rem !important;
}

.card-gallery:hover {
  height: 20rem !important;
  width: 20rem !important;
  position: absolute;
  margin-top: -2rem;
  z-index: 999;
  transition: 0.5s;
}

.card-gallery:focus {
  height: 20rem !important;
  width: 20rem !important;
  position: absolute;
  margin-top: -2rem;
  z-index: 999;
  transition: 0.5s;
}

.ant-upload-list-item-card-actions .ant-btn {
  border-radius: 0px !important;
  height: 25px !important;
}

.ant-upload > span > .ant-btn {
  color: #cd1f30 !important;
  border: 1px solid #cd1f30 !important;
  background: transparent !important;
}

.ant-upload > span > .ant-btn:hover {
  color: #cd1f30 !important;
  border: 1px solid #cd1f30 !important;
  background: transparent !important;
}

.font-arial {
  font-family: Arial !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border: 2px solid #cd1f30 !important;
  color: #cd1f30 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #cd1f30;
}

.show-terms {
  width: 100%;
  transition: 0.5s;
}

.hide-terms {
  width: 0;
  transition: 0.5s;
}

.show-policy {
  width: 100%;
  transition: 0.5s;
}

.hide-policy {
  width: 0;
  transition: 0.5s;
}

.ant-checkbox {
  background-color: red;
  border: 1px solid black;
}
